import { Box } from "@mui/material";
import React, { Fragment } from "react";
import classess from "./Bodi.module.css";
import SpaIcon from "@mui/icons-material/Spa";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import Main from "../main/Main";
import ArrowForwardIos from "./ArrowForwardIos";
import Hinzufügen from "../../pages/hinzufügen/Hinzufügen";
import Spiel from "../../pages/spiel/Spiel";
import Gesamt from "../../pages/gesamt/Gesamt";
import HinzufügenVerb from "../../pages/hinzufügen_verb/HinzufügenVerb";
import AlleVerb from "../../pages/alle_verb/AlleVerb";
import HinzufügenRegeln from "../../pages/hinzufügen_regeln/HinzufügenRegeln";
import AlleRegeln from "../../pages/alle_regeln/AlleRegeln";
import BesondersRegeln from "../../pages/besonders_regeln/BesondersRegeln";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";

function Bodi({
  Result,
  Menu,
  setMenu,
  UbenArray,
  RegelnArray,
  VerbArray,
  SubMenu,
  setSubMenu,
  ShowItemsVerb,
  setShowItemsVerb,
  ShowItemsRegeln,
  setShowItemsRegeln,
}) {
  const onClickMenu = (e) => {
    setMenu(e);
    setShowItemsVerb(null);
    setShowItemsRegeln(null);
    if (e === "Verb") setSubMenu(VerbArray[1]);
    if (e === "Üben") setSubMenu(UbenArray[2]);
    if (e === "Regeln") setSubMenu(RegelnArray[2]);
  };
  return (
    <Box className={classess.Bodi}>
      <Box className={classess.Header}>
        <Box
          className={classess.Item}
          onClick={(e) => onClickMenu("Verb")}
          style={{
            color: Menu === "Verb" ? "#048e7b" : null,
          }}
        >
          <SpaIcon />
          <span>Verb</span>
        </Box>
        <Box
          className={classess.Item}
          onClick={(e) => onClickMenu("Üben")}
          style={{
            color: Menu === "Üben" ? "#048e7b" : null,
          }}
        >
          <BeachAccessIcon />
          <span>Üben</span>
        </Box>
        <Box
          className={classess.Item}
          onClick={(e) => onClickMenu("Regeln")}
          style={{
            color: Menu === "Regeln" ? "#048e7b" : null,
          }}
        >
          <SportsBasketballIcon />
          <span>Regeln</span>
        </Box>
      </Box>
      <Box className={classess.SubHeader}>
        <Box>
          {Menu === "" || Menu.length === 0 ? null : (
            <Fragment>
              {Menu}
              <ArrowForwardIos />
              {Menu === "Verb" && ShowItemsVerb === null ? (
                <Fragment>
                  <span
                    className={classess.Menu}
                    onClick={() => setSubMenu(VerbArray[0])}
                    style={{
                      color:
                        Menu === "Verb" && SubMenu === VerbArray[0]
                          ? "#048e7b"
                          : null,
                    }}
                  >
                    {VerbArray[0]}
                  </span>
                  <ArrowForwardIos />
                  <span
                    className={classess.Menu}
                    onClick={() => setSubMenu(VerbArray[1])}
                    style={{
                      color:
                        Menu === "Verb" && SubMenu === VerbArray[1]
                          ? "#048e7b"
                          : null,
                    }}
                  >
                    {VerbArray[1]}
                  </span>
                </Fragment>
              ) : Menu === "Verb" && ShowItemsVerb !== null ? (
                <span style={{ fontFamily: "Tahoma" }}>{ShowItemsVerb[0]}</span>
              ) : null}
              {Menu === "Üben" ? (
                <Fragment>
                  <span
                    className={classess.Menu}
                    onClick={() => setSubMenu(UbenArray[0])}
                    style={{
                      color:
                        Menu === "Üben" && SubMenu === UbenArray[0]
                          ? "#048e7b"
                          : null,
                    }}
                  >
                    {UbenArray[0]}
                  </span>
                  <ArrowForwardIos />
                  <span
                    className={classess.Menu}
                    onClick={() => setSubMenu(UbenArray[1])}
                    style={{
                      color:
                        Menu === "Üben" && SubMenu === UbenArray[1]
                          ? "#048e7b"
                          : null,
                    }}
                  >
                    {UbenArray[1]}
                  </span>
                  <ArrowForwardIos />
                  <span
                    className={classess.Menu}
                    onClick={() => setSubMenu(UbenArray[2])}
                    style={{
                      color:
                        Menu === "Üben" && SubMenu === UbenArray[2]
                          ? "#048e7b"
                          : null,
                    }}
                  >
                    {UbenArray[2]}
                  </span>
                </Fragment>
              ) : null}
              {Menu === "Regeln" && ShowItemsRegeln === null ? (
                <Fragment>
                  <span
                    className={classess.Menu}
                    onClick={() => setSubMenu(RegelnArray[0])}
                    style={{
                      color:
                        Menu === "Regeln" && SubMenu === RegelnArray[0]
                          ? "#048e7b"
                          : null,
                    }}
                  >
                    {RegelnArray[0]}
                  </span>
                  <ArrowForwardIos />
                  <span
                    className={classess.Menu}
                    onClick={() => setSubMenu(RegelnArray[1])}
                    style={{
                      color:
                        Menu === "Regeln" && SubMenu === RegelnArray[1]
                          ? "#048e7b"
                          : null,
                    }}
                  >
                    {RegelnArray[1]}
                  </span>
                  <ArrowForwardIos />
                  <span
                    className={classess.Menu}
                    onClick={() => setSubMenu(RegelnArray[2])}
                    style={{
                      color:
                        Menu === "Regeln" && SubMenu === RegelnArray[2]
                          ? "#048e7b"
                          : null,
                    }}
                  >
                    {RegelnArray[2]}
                  </span>
                </Fragment>
              ) : Menu === "Regeln" && ShowItemsRegeln !== null ? (
                <span style={{ fontFamily: "Tahoma" }}>
                  {ShowItemsRegeln[0]}
                </span>
              ) : null}
            </Fragment>
          )}
        </Box>
        {ShowItemsVerb === null && ShowItemsRegeln === null ? null : (
          <ArrowBackRoundedIcon
            sx={{
              fontSize: "1.2rem",
              marginTop: "1px",
              marginRight: "3px",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowItemsVerb(null);
              setShowItemsRegeln(null);
            }}
          />
        )}
      </Box>
      <Box className={classess.Main}>
        {Menu === "" || Menu.length === 0 ? (
          <Main Result={Result} />
        ) : (
          <Fragment>
            {SubMenu === "Hinzufügen" && Menu === "Üben" ? (
              <Hinzufügen setSubMenu={setSubMenu} />
            ) : null}
            {SubMenu === "Spiel" && Menu === "Üben" ? (
              <Spiel setSubMenu={setSubMenu} />
            ) : null}
            {SubMenu === "Gesamt" && Menu === "Üben" ? (
              <Gesamt setSubMenu={setSubMenu} />
            ) : null}
            {SubMenu === "Hinzufügen" && Menu === "Verb" ? (
              <HinzufügenVerb />
            ) : null}
            {SubMenu === "Alle" && Menu === "Verb" ? (
              <AlleVerb
                ShowItemsVerb={ShowItemsVerb}
                setShowItemsVerb={setShowItemsVerb}
              />
            ) : null}
            {SubMenu === "Hinzufügen" && Menu === "Regeln" ? (
              <HinzufügenRegeln />
            ) : null}
            {SubMenu === "Alle" && Menu === "Regeln" ? (
              <AlleRegeln
                ShowItemsRegeln={ShowItemsRegeln}
                setShowItemsRegeln={setShowItemsRegeln}
                setSubMenu={setSubMenu}
              />
            ) : null}
            {SubMenu === "Besonders" && Menu === "Regeln" ? (
              <BesondersRegeln />
            ) : null}
          </Fragment>
        )}
      </Box>
    </Box>
  );
}

export default Bodi;
