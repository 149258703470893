import { Box } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import classess from "./AlleVerb.module.css";
import AXIOS from "../../AXIOS";
import Empty from "../../components/empty/Empty";
import Loadings from "../../components/loading/Loadings";
import SearchIcon from "@mui/icons-material/Search";
import Items from "./Items";
import Itemdetailes from "./Itemdetailes";

const AlleVerb = ({ ShowItemsVerb, setShowItemsVerb }) => {
  const [LoadingPage, setLoadingPage] = useState(true);
  const [Result, setResult] = useState([]);
  const [Emptys, setEmptys] = useState(false);

  const fetchData = async () => {
    await AXIOS.get("/LBG/VERB.json")
      .then((response) => {
        if (response.status === 200) {
          setLoadingPage(false);
          setResult(
            response.data === null ? "" : Object.entries(response.data)
          );
          // console.log(Object.entries(response.data)[0][1]);
          // console.log(Object.entries(response.data)[0][1].Futur);
          // console.log(Object.entries(response.data)[0][1].Futur.ICH);
        }
      })
      .catch((err) => {
        console.warn(err);
        setEmptys(true);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box className={classess.Up}>
      {LoadingPage ? (
        <Loadings />
      ) : Result.length === 0 || Emptys ? (
        <Empty onClick={() => window.location.reload()} />
      ) : ShowItemsVerb === null ? (
        <Fragment>
          <Box className={classess.Search}>
            <Box className={classess.InputContainer}>
              <input className={classess.Input} placeholder="Search..." />
              <span className={classess.SearchIcon}>
                <SearchIcon className={classess.SearchIcons} />
              </span>
            </Box>
          </Box>
          <Box className={classess.Items}>
            {Result.map((opt, index) => (
              <Items
                key={index}
                OnClick={() => setShowItemsVerb(opt)}
                opt={opt}
              />
            ))}
          </Box>
        </Fragment>
      ) : (
        <Itemdetailes opt={ShowItemsVerb} />
      )}
    </Box>
  );
};

export default AlleVerb;
