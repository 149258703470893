import { Box } from "@mui/material";
import React from "react";
import classess from "./Style.module.css";
import Noch from "./Noch";

const Nicht = ({ Result }) => {
  const Title = ["کلمه", "معنی", "کاربرد"];
  const ST1 = ["nicht", "نه/نیست", "برای افعال، صفات، قیدها، جملات"];
  const ST2 = ["kein", "هیچ/نه", "برای اسم‌های قابل شمارش/غیرقابل شمارش"];
  const ST3 = ["nein", "نه", "برای پاسخ‌های مستقیم"];
  const ST4 = ["noch", "هنوز  نه", "هنوز اتفاق نیفتاده یا هنوز وجود ندارد"];
  const ST5 = ["nichts", "هیچ‌چیز", "برای نفی کامل اشیاء یا موضوعات"];
  const ST6 = ["nie", "هیچ‌وقت/هرگز", "برای نفی زمان"];

  return (
    <Box className={classess.Wrapper}>
      <span>جدول حالت‌های اسم مذکر با „ein“:</span>
      <Box className={classess.WP}>
        {Title.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "50px" : index === 1 ? "80px" : "200px",
              backgroundColor: "rgba(150, 150, 150, 0.1)",
              fontSize: "0.9rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST1.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "50px" : index === 1 ? "80px" : "200px",
              fontSize: "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST2.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "50px" : index === 1 ? "80px" : "200px",
              fontSize: index === 2 ? "0.8rem" : "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST3.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "50px" : index === 1 ? "80px" : "200px",
              fontSize: "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST4.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "50px" : index === 1 ? "80px" : "200px",
              fontSize: index === 2 ? "0.95rem" : "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST5.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "50px" : index === 1 ? "80px" : "200px",
              fontSize: "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST6.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "50px" : index === 1 ? "80px" : "200px",
              fontSize: index === 1 ? "0.8rem" : "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.Footers}>
        <span>
          Verben: „ <label className={classess.Label}>Ich arbeite nicht.</label>
          “
        </span>
        <span>
          Attribute: „{" "}
          <label className={classess.Label}>Das ist nicht schön.</label>“
        </span>
        <span>
          Adverbien: „{" "}
          <label className={classess.Label}>Er kommt nicht heute.</label>“
        </span>
        <span>
          Ganzen Satz: „{" "}
          <label className={classess.Label}>Ich habe nicht Hunger.</label>“
        </span>
        <span style={{ marginTop: "10px" }}>
          Zählbar: „{" "}
          <label className={classess.Label}>Nein, ich habe kein Buch.</label>“
        </span>
        <span>
          Unzählbar: „{" "}
          <label className={classess.Label}>Nein, ich habe kein Geld.</label>“
        </span>
        <span style={{ marginTop: "10px" }}>
          Direkte Antwort: „{" "}
          <label className={classess.Label}>Kommst du mit? Nein!</label>“
        </span>
        <span style={{ marginTop: "10px" }}>
          Noch: „{" "}
          <label className={classess.Label}>
            Ich habe das Buch noch nicht gelesen.
          </label>
          “
        </span>
        <span>
          Noch: „{" "}
          <label className={classess.Label}>
            Wir haben noch keinen Termin.
          </label>
          “
        </span>
        <span style={{ marginTop: "10px" }}>
          Nichts: „{" "}
          <label className={classess.Label}>Ich habe nichts gesehen.</label>“
        </span>
        <span>
          Nichts: „ <label className={classess.Label}>Er sagt nichts.</label>“
        </span>
        <span style={{ marginTop: "10px" }}>
          Nie: „{" "}
          <label className={classess.Label}>Ich gehe nie ins Kino.</label>“
        </span>
        <span>
          Nie: „{" "}
          <label className={classess.Label}> Sie hat das nie gesagt.</label>“
        </span>
      </Box>
      <Noch Result={Result} />
    </Box>
  );
};

export default Nicht;
