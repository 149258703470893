import { Box } from "@mui/material";
import React, { Fragment } from "react";
import classess from "./AlleVerb.module.css";
import ballon from "../../assets/Ballon.jpg";
import car from "../../assets/Car.jpg";
import sheep from "../../assets/Sheep.jpg";
import Cicle from "../../assets/cicle.jpg";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const Itemdetailes = ({ opt }) => {
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgb(30,30,30)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgb(30,30,30)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontFamily: "Tahoma",
      padding: "10px",
      letterSpacing: "1px",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      display: "inline-block",
      width: "auto",
      maxWidth: "none",
      whiteSpace: "nowrap",
      textAlign: "center",
      transform: "none",
    },
  }));
  const formatString = (str) => {
    if (!str) return "";
    const updatedStr = str.replace("(", "[").replace(")", "]");
    return updatedStr;
  };
  return (
    <Box className={classess.Itemdetailes}>
      {Object.entries(opt[1]).map((option, index) => (
        <Fragment key={index}>
          <Box className={classess.th0}>
            {index === 0 ? (
              <img src={ballon} alt="" />
            ) : index === 1 ? (
              <img src={car} alt="" />
            ) : index === 2 ? (
              <img src={sheep} alt="" />
            ) : (
              <img src={Cicle} alt="" />
            )}
            <span>{option[0]}</span>
          </Box>
          <Box className={classess.th1}>
            <Box className={classess.th2}>ICH</Box>
            <Box className={classess.th3}>
              <BootstrapTooltip
                title={
                  index === 0
                    ? formatString(opt[1].Futur.ICH.split("-")[1])
                    : index === 1
                    ? formatString(opt[1].Perfekt.ICH.split("-")[1])
                    : index === 2
                    ? formatString(opt[1].Präsens.ICH.split("-")[1])
                    : formatString(opt[1].Präteritum.ICH.split("-")[1])
                }
              >
                {index === 0
                  ? opt[1].Futur.ICH.split("-")[0]
                  : index === 1
                  ? opt[1].Perfekt.ICH.split("-")[0]
                  : index === 2
                  ? opt[1].Präsens.ICH.split("-")[0]
                  : opt[1].Präteritum.ICH.split("-")[0]}
              </BootstrapTooltip>
            </Box>
          </Box>
          <Box className={classess.th1}>
            <Box className={classess.th2}>DU</Box>
            <Box className={classess.th3}>
              <BootstrapTooltip
                title={
                  index === 0
                    ? formatString(opt[1].Futur.DU.split("-")[1])
                    : index === 1
                    ? formatString(opt[1].Perfekt.DU.split("-")[1])
                    : index === 2
                    ? formatString(opt[1].Präsens.DU.split("-")[1])
                    : formatString(opt[1].Präteritum.DU.split("-")[1])
                }
              >
                {index === 0
                  ? opt[1].Futur.DU.split("-")[0]
                  : index === 1
                  ? opt[1].Perfekt.DU.split("-")[0]
                  : index === 2
                  ? opt[1].Präsens.DU.split("-")[0]
                  : opt[1].Präteritum.DU.split("-")[0]}
              </BootstrapTooltip>
            </Box>
          </Box>
          <Box className={classess.th1}>
            <Box className={classess.th2}>ER/SIE/ES</Box>
            <Box className={classess.th3}>
              <BootstrapTooltip
                title={
                  index === 0
                    ? formatString(opt[1].Futur.ER.split("-")[1])
                    : index === 1
                    ? formatString(opt[1].Perfekt.ER.split("-")[1])
                    : index === 2
                    ? formatString(opt[1].Präsens.ER.split("-")[1])
                    : formatString(opt[1].Präteritum.ER.split("-")[1])
                }
              >
                {index === 0
                  ? opt[1].Futur.ER.split("-")[0]
                  : index === 1
                  ? opt[1].Perfekt.ER.split("-")[0]
                  : index === 2
                  ? opt[1].Präsens.ER.split("-")[0]
                  : opt[1].Präteritum.ER.split("-")[0]}
              </BootstrapTooltip>
            </Box>
          </Box>
          <Box className={classess.th1}>
            <Box className={classess.th2}>WIR</Box>
            <Box className={classess.th3}>
              <BootstrapTooltip
                title={
                  index === 0
                    ? formatString(opt[1].Futur.WIR.split("-")[1])
                    : index === 1
                    ? formatString(opt[1].Perfekt.WIR.split("-")[1])
                    : index === 2
                    ? formatString(opt[1].Präsens.WIR.split("-")[1])
                    : formatString(opt[1].Präteritum.WIR.split("-")[1])
                }
              >
                {index === 0
                  ? opt[1].Futur.WIR.split("-")[0]
                  : index === 1
                  ? opt[1].Perfekt.WIR.split("-")[0]
                  : index === 2
                  ? opt[1].Präsens.WIR.split("-")[0]
                  : opt[1].Präteritum.WIR.split("-")[0]}
              </BootstrapTooltip>
            </Box>
          </Box>
          <Box className={classess.th1}>
            <Box className={classess.th2}>IHR</Box>
            <Box className={classess.th3}>
              <BootstrapTooltip
                title={
                  index === 0
                    ? formatString(opt[1].Futur.IHR.split("-")[1])
                    : index === 1
                    ? formatString(opt[1].Perfekt.IHR.split("-")[1])
                    : index === 2
                    ? formatString(opt[1].Präsens.IHR.split("-")[1])
                    : formatString(opt[1].Präteritum.IHR.split("-")[1])
                }
              >
                {index === 0
                  ? opt[1].Futur.IHR.split("-")[0]
                  : index === 1
                  ? opt[1].Perfekt.IHR.split("-")[0]
                  : index === 2
                  ? opt[1].Präsens.IHR.split("-")[0]
                  : opt[1].Präteritum.IHR.split("-")[0]}
              </BootstrapTooltip>
            </Box>
          </Box>
          <Box className={classess.th1}>
            <Box className={classess.th2}>sie/Sie</Box>
            <Box className={classess.th3}>
              <BootstrapTooltip
                title={
                  index === 0
                    ? formatString(opt[1].Futur.SIE.split("-")[1])
                    : index === 1
                    ? formatString(opt[1].Perfekt.SIE.split("-")[1])
                    : index === 2
                    ? formatString(opt[1].Präsens.SIE.split("-")[1])
                    : formatString(opt[1].Präteritum.SIE.split("-")[1])
                }
              >
                {index === 0
                  ? opt[1].Futur.SIE.split("-")[0]
                  : index === 1
                  ? opt[1].Perfekt.SIE.split("-")[0]
                  : index === 2
                  ? opt[1].Präsens.SIE.split("-")[0]
                  : opt[1].Präteritum.SIE.split("-")[0]}
              </BootstrapTooltip>
            </Box>
          </Box>
        </Fragment>
      ))}
    </Box>
  );
};

export default Itemdetailes;
