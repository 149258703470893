import React, { Fragment } from "react";
import classess from "./RoadMap.module.css";
import { Box } from "@mui/material";

const RoadMap = ({ RoadMaps, currentIndex }) => {
  const COLOR_GRAY = "rgba(150,150,150,0.3)";
  const COLOR_GREEN = "#048e7b";
  const COLOR_YELLOW = "rgb(255, 238, 0)";
  const COLOR_BORDER_YELLOW = "2px solid rgb(255, 238, 0)";
  const COLOR_BORDER_GRAY = "2px solid rgba(150,150,150,0.3)";
  return (
    <Fragment>
      <Box className={classess.RoadMap}>
        {RoadMaps.map((opt, index) => (
          <Box
            key={index}
            className={classess.RM}
            sx={{
              marginLeft: index !== 0 ? "-1px" : null,
            }}
          >
            <Box className={classess.Circle}>
              <Box
                className={classess.C3}
                sx={{
                  backgroundColor:
                    index <= currentIndex ? COLOR_YELLOW : COLOR_GRAY,
                }}
              ></Box>
              <Box
                className={classess.C1}
                sx={{
                  border:
                    index <= currentIndex
                      ? COLOR_BORDER_YELLOW
                      : COLOR_BORDER_GRAY,
                }}
              >
                <Box
                  className={classess.C2}
                  sx={{
                    backgroundColor:
                      index <= currentIndex ? COLOR_YELLOW : COLOR_GRAY,
                  }}
                ></Box>
              </Box>
              <Box
                className={classess.C3}
                sx={{
                  backgroundColor:
                    index <= currentIndex ? COLOR_YELLOW : COLOR_GRAY,
                }}
              ></Box>
            </Box>
            <Box
              className={classess.Circle}
              sx={{
                color: index <= currentIndex ? COLOR_GREEN : COLOR_GRAY,
              }}
            >
              {opt}
            </Box>
          </Box>
        ))}
      </Box>
    </Fragment>
  );
};

export default RoadMap;
