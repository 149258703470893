import { Box } from "@mui/material";
import React from "react";
import trr2 from "../../assets/tree2.jpg";
import classess from "./Style.module.css";

const Tree = ({ T }) => {
  return (
    <Box className={classess.Tree}>
      <img src={trr2} alt="" className={classess.trr2} />
      <span className={classess.AkkusativTree}>{T}</span>
    </Box>
  );
};

export default Tree;
