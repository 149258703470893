import React, { Fragment } from "react";
import classess from "./Unicode.module.css";
import { Box } from "@mui/material";

const Unicode = ({ Response, setResponse, textareaRef }) => {
  const Onclick = (e) => {
    setResponse(Response + e);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };
  return (
    <Fragment>
      <Box className={classess.Unicode}>
        <Box className={classess.Uni}></Box>
        <abbr title="0196">
          <Box className={classess.Uni} onClick={() => Onclick("Ä")}>
            Ä
          </Box>
        </abbr>
        <abbr title="0228">
          <Box className={classess.Uni} onClick={() => Onclick("ä")}>
            ä
          </Box>
        </abbr>
        <abbr title="0233">
          <Box className={classess.Uni} onClick={() => Onclick("é")}>
            é
          </Box>
        </abbr>
        <abbr title="0214">
          <Box className={classess.Uni} onClick={() => Onclick("Ö")}>
            Ö
          </Box>
        </abbr>
        <abbr title="0246">
          <Box className={classess.Uni} onClick={() => Onclick("ö")}>
            ö
          </Box>
        </abbr>
        <abbr title="0220">
          <Box className={classess.Uni} onClick={() => Onclick("Ü")}>
            Ü
          </Box>
        </abbr>
        <abbr title="0252">
          <Box className={classess.Uni} onClick={() => Onclick("ü")}>
            ü
          </Box>
        </abbr>
        <abbr title="0223">
          <Box className={classess.Uni} onClick={() => Onclick("ß")}>
            ß
          </Box>
        </abbr>
      </Box>
    </Fragment>
  );
};

export default Unicode;
