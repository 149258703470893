import React, { Fragment, useEffect, useRef, useState } from "react";
import classess from "./HinzufügenRegeln.module.css";
import { Box } from "@mui/material";
import Empty from "../../components/empty/Empty";
import Unicode from "../../components/unicode/Unicode";
import BTN from "../../components/btn/BTN";
import AXIOS from "../../AXIOS";

const HinzufügenRegeln = () => {
  const [LoadSave, setLoadSave] = useState(false);
  const [GR, setGR] = useState("");
  const [EN, setEN] = useState("");
  const [Emptys, setEmptys] = useState(false);
  const REF1 = useRef(null);
  const REF2 = useRef(null);
  const [Response, setResponse] = useState("");
  const storedItem = localStorage.getItem("Regeln");
  const [Stored, setStored] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (GR.length !== 0 && EN.length !== 0) {
      setLoadSave(true);
      await AXIOS.put(
        `/LBG/REGELN/${Stored === null ? GR.trim() : Stored[0]}.json`,
        {
          EN: EN.trim(),
        }
      )
        .then((response) => {
          if (response.status === 200) {
            setLoadSave(false);
            setGR("");
            setEN("");
          }
        })
        .catch((err) => {
          console.warn(err);
          setEmptys(true);
        });
    }
  };
  useEffect(() => {
    if (storedItem) {
      const parsedItem = JSON.parse(storedItem);
      setGR(parsedItem[0]);
      setEN(parsedItem[1].EN);
      setStored(parsedItem);
    }
    localStorage.removeItem("Regeln");
  }, []);

  return (
    <Box className={classess.Up}>
      {Emptys ? (
        <Empty onClick={() => window.location.reload()} />
      ) : (
        <Fragment>
          <Unicode
            Response={Response === "GR" ? GR : EN}
            setResponse={Response === "GR" ? setGR : setEN}
            textareaRef={Response === "GR" ? REF1 : REF2}
          />
          <input
            className={classess.GR}
            placeholder="Betreten Sie Deutschland"
            value={GR}
            onChange={(e) => setGR(e.target.value)}
            onKeyDown={() => setResponse("GR")}
            ref={REF1}
          />
          <textarea
            className={classess.EN}
            placeholder="Betreten Sie English"
            value={EN}
            onChange={(e) => setEN(e.target.value)}
            onKeyDown={() => setResponse("EN")}
            ref={REF2}
          />
          {LoadSave ? (
            <BTN
              Text="Laden ..."
              //onClick={onClick}
              P="fixed"
              W="340px"
              MH="50px"
              BC="#d8f3ee"
              C="#028472"
              MT="calc(100vh - 170px)"
              ML="0"
              B="1px solid #028472"
            />
          ) : Stored !== null ? (
            <BTN
              Text="Karte Bearbeiten"
              onClick={handleSubmit}
              P="fixed"
              W="340px"
              MH="50px"
              BC="#028472"
              C="white"
              MT="calc(100vh - 170px)"
              ML="0"
              B="null"
            />
          ) : (
            <BTN
              Text="Karte Hinzufügen"
              onClick={handleSubmit}
              P="fixed"
              W="340px"
              MH="50px"
              BC="#028472"
              C="white"
              MT="calc(100vh - 170px)"
              ML="0"
              B="null"
            />
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default HinzufügenRegeln;
