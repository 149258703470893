import { Box } from "@mui/material";
import React from "react";
import classess from "./Style.module.css";

const Dative = () => {
  const Title = ["جنسیت", "معرفه", "نکره", "بدون حرف"];
  const ST1 = ["مذکر", "dem", "einem", "-em"];
  const ST2 = ["مؤنث", "der", "einer", "-er"];
  const ST3 = ["خنثی", "dem", "einem", "-em"];
  const ST4 = ["جمع", "den", "-", "-en"];
  return (
    <Box className={classess.Wrapper}>
      <span>تغییرات حرف تعریف در داتیو:</span>
      <Box className={classess.WP}>
        {Title.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              backgroundColor: "rgba(150, 150, 150, 0.1)",
              fontSize: "0.9rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST1.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize: index === 0 ? "0.8rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST2.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize: index === 0 ? "0.8rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST3.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize: index === 0 ? "0.8rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST4.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize: index === 0 ? "0.8rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Dative;
