import React, { Fragment } from "react";
import { Box } from "@mui/material";
import classess from "./Main.module.css";
import DandelionLarg from "../../assets/DandelionLarg2.png";
import D from "../../assets/D.jpg";

const CircleDesign = ({ Percent }) => {
  const points = 70;
  const radius = 70;
  const radius2 = 73;
  const elements = Array.from({ length: points }).map((_, i) => {
    const angle = (i / points) * 2 * Math.PI;
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);
    return (
      <g key={i} transform={`translate(${x}, ${y})`}>
        <line
          x1={0}
          y1={0}
          x2={(radius / 15) * Math.cos(angle)}
          y2={(radius / 15) * Math.sin(angle)}
          stroke="rgba(77,38,28,0.5)"
          strokeWidth={1}
        />
      </g>
    );
  });
  const elements2 = Array.from({ length: points }).map((_, i) => {
    const angle = (i / points) * 2 * Math.PI;
    const x = radius2 * Math.cos(angle);
    const y = radius2 * Math.sin(angle);
    return (
      <g key={i} transform={`translate(${x}, ${y})`}>
        {i % 5 === 0 && <circle cx={0} cy={0} r={2} fill="black" />}
      </g>
    );
  });

  return (
    <Fragment>
      <img src={D} alt="" className={classess.D} />
      <svg
        width={radius * 2 + 50}
        height={radius * 2 + 50}
        viewBox={`-${radius + 25} -${radius + 25} ${radius * 2 + 50} ${
          radius * 2 + 50
        }`}
      >
        {elements}
        {elements2}
      </svg>
      <Box
        className={classess.Ring}
        sx={{
          "--stop1": `${Percent}%`,
        }}
      >
        <img src={DandelionLarg} alt="" />
      </Box>
    </Fragment>
  );
};

export default CircleDesign;
