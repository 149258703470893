import { Box } from "@mui/material";
import React from "react";
import classess from "./Style.module.css";

const Articles = () => {
  const Title = ["Modus", "DER", "DIE", "DAS", "Plural"];
  const ST1 = ["Nominativ (فاعل)", "der", "die", "das", "die"];
  const ST2 = ["Akkusativ", "den", "die", "das", "die"];
  const ST3 = ["Dativ", "dem", "der", "dem", "den(+n)"];
  const ST4 = ["Genitiv (مالکیت)", "des(+s/es)", "der", "des(+s/es)", "der"];
  const Title2 = ["Modus", "EIN", "EINE", "EIN", "Plural-"];
  const ST5 = ["Nominativ (فاعل)", "ein", "eine", "ein", "-"];
  const ST6 = ["Akkusativ", "einen", "eine", "ein", "-"];
  const ST7 = ["Dativ", "einem", "einer", "einem", "-"];
  const ST8 = [
    "Genitiv (مالکیت)",
    "eines (+s/es)",
    "einer",
    "eines (+s/es)",
    "-",
  ];

  return (
    <Box className={classess.Wrapper}>
      <span>1. آرتیکل‌های معین (Definite Articles):</span>
      <Box className={classess.WP}>
        {Title.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              backgroundColor: "rgba(150, 150, 150, 0.1)",
              fontSize: "0.9rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST1.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize: index === 0 ? "0.8rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST2.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize: index === 0 ? "0.8rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST3.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize:
                index === 0 ? "0.8rem" : index === 4 ? "0.95rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST4.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize:
                index === 0
                  ? "0.8rem"
                  : index === 1 || index === 3
                  ? "0.9rem"
                  : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <span style={{ marginTop: "15px" }}>
        2. آرتیکل‌های نامعین (Indefinite Articles):
      </span>
      <Box className={classess.WP}>
        {Title2.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              backgroundColor: "rgba(150, 150, 150, 0.1)",
              fontSize: "0.9rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST5.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize: index === 0 ? "0.8rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST6.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize: index === 0 ? "0.8rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST7.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize: index === 0 ? "0.8rem" : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST8.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "90px" : "55px",
              fontSize:
                index === 0
                  ? "0.8rem"
                  : index === 1 || index === 3
                  ? "0.75rem"
                  : "1.2rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Articles;
