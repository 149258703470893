import { Box } from "@mui/material";
import classess from "./Spiel.module.css";
import React from "react";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";

const EN = ({ Item, Type, setSubMenu }) => {
  const Forwrds = () => {
    if (Type.length !== 0 || Type !== "") {
      setSubMenu("Hinzufügen");
      localStorage.setItem("Item", JSON.stringify(Item));
    }
  };
  return (
    <Box className={classess.EN}>
      <span>{Type.length === 0 || Type === "" ? Item.EN : Item.GR}</span>
      {Type.length === 0 || Type === "" ? null : (
        <AppRegistrationRoundedIcon
          sx={{
            fontSize: "1rem",
            marginLeft: "8px",
            color: "rgb(0, 0, 0, 0.5)",
            cursor: "pointer",
            marginTop: "5px",
          }}
          onClick={Forwrds}
        />
      )}
    </Box>
  );
};

export default EN;
