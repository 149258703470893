import React, { useEffect, useState } from "react";
import classess from "./Spiel.module.css";
import { Box } from "@mui/material";

const GR = ({ GR, Errors, GRDay }) => {
  const [Error, setError] = useState(false);
  useEffect(() => {
    if (Errors) setError(true);
  }, []);
  return (
    <Box className={classess.GR}>
      <Box
        className={classess.GRBox}
        sx={{
          backgroundColor: Error
            ? "rgba(255, 0, 0, 0.1)"
            : "rgb(243, 242, 247, 0.6)",
          color: Error ? "color: rgb(144, 7, 7)" : "rgba(0,0,0,0.7)",
        }}
      >
        {GR}
      </Box>
      <span className={classess.Karte}>Karte für den {GRDay} Tag</span>
    </Box>
  );
};

export default GR;
