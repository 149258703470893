import React from "react";
import classess from "./BTN.module.css";
import { Box } from "@mui/material";
import EastRoundedIcon from "@mui/icons-material/EastRounded";

const BTN = ({ Text, onClick, P, W, MH, BC, C, MT, ML, B }) => {
  return (
    <Box
      className={classess.BTN}
      sx={{
        position: P,
        width: W,
        minHeight: MH,
        backgroundColor: BC,
        color: C,
        marginTop: MT,
        marginLeft: ML,
        border: B,
      }}
      onClick={onClick}
    >
      {Text === 0 || Text.length === 0 ? <EastRoundedIcon /> : Text}
    </Box>
  );
};

export default BTN;
