import { Box } from "@mui/material";
import React from "react";
import classess from "./Style.module.css";

const Einen = () => {
  const Title = ["شکل مفرد (Maskulin)", "حالت دستوری (Kasus)"];
  const ST1 = ["ein Freund", "Nominativ (فاعل)"];
  const ST2 = ["einen Freund", "Akkusativ (مفعول مستقیم)"];
  const ST3 = ["einem Freund", "Dativ (مفعول غیرمستقیم)"];
  const ST4 = ["eines Freund", "Genitiv (اضافه)"];

  return (
    <Box className={classess.Wrapper}>
      <span>جدول حالت‌های اسم مذکر با „ein“:</span>
      <Box className={classess.WP}>
        {Title.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "120px" : "180px",
              backgroundColor: "rgba(150, 150, 150, 0.1)",
              fontSize: "0.9rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST1.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "120px" : "180px",
              fontSize: "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST2.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "120px" : "180px",
              fontSize: "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST3.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "120px" : "180px",
              fontSize: "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.WP}>
        {ST4.map((opt, index) => (
          <Box
            key={index}
            className={classess.WB}
            sx={{
              width: index === 0 ? "120px" : "180px",
              fontSize: "1.1rem",
            }}
          >
            {opt}
          </Box>
        ))}
      </Box>
      <Box className={classess.Footers}>
        <span>
          Nominativ: „{" "}
          <label className={classess.Label}>Ein Freund hilft mir.</label>“
        </span>
        <span className={classess.Span}>
          (یک دوست به من کمک می‌کند. فاعل جمله است.)
        </span>
        <span style={{ marginTop: "8px" }}>
          Akkusativ: „{" "}
          <label className={classess.Label}>Ich habe einen Freund.</label>“
        </span>
        <span className={classess.Span}>
          (من یک دوست دارم. مفعول مستقیم جمله است.)
        </span>
      </Box>
    </Box>
  );
};

export default Einen;
