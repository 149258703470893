import React, { Fragment, useEffect, useState } from "react";
import classess from "./Main.module.css";
import { Box } from "@mui/material";
import CircleDesign from "./CircleDesign";

const Main = ({ Result }) => {
  const [Percent, setPercent] = useState(0);
  useEffect(() => {
    const F = Result.filter((item) => item.DAY === 0);
    setPercent((100 / Result.length) * (Result.length - F.length));
  }, []);
  return (
    <Box className={classess.Main}>
      <Box className={classess.Account}>
        <Box className={classess.AUS}>AUS</Box>
        <Box className={classess.COFFEE}>
          <CircleDesign Percent={Percent} />
        </Box>
        <Box className={classess.ANS}>AN</Box>
      </Box>
      <Box>
        <span className={classess.GUTEN}>
          <span>GUTEN</span>
          <span>TAG</span>
          <span>
            {Result === null || Result.length === 0 ? "0" : Result.length} '
          </span>
        </span>
      </Box>
    </Box>
  );
};

export default Main;
