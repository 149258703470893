import { Box } from "@mui/material";
import "./App.css";
import AXIOS from "./AXIOS";
import Empty from "./components/empty/Empty";
import Loadings from "./components/loading/Loadings";
import { Fragment, useEffect, useState } from "react";
import Bodi from "./components/bodi/Bodi";
import CurrentDate from "./components/date/CurrentDate";

function App() {
  const [LoadingPage, setLoadingPage] = useState(true);
  const [Result, setResult] = useState([]);
  const [Emptys, setEmptys] = useState(false);
  const [Menu, setMenu] = useState("");
  const [SubMenu, setSubMenu] = useState("Hinzufügen");
  const UbenArray = ["Spiel", "Gesamt", "Hinzufügen"];
  const RegelnArray = ["Besonders", "Alle", "Hinzufügen"];
  const VerbArray = ["Alle", "Hinzufügen"];
  const [ShowItemsVerb, setShowItemsVerb] = useState(null);
  const [ShowItemsRegeln, setShowItemsRegeln] = useState(null);

  const fetchData = async () => {
    setLoadingPage(true);
    await AXIOS.get("/LBG/DATA.json")
      .then((response) => {
        if (response.status === 200) {
          setLoadingPage(false);
          // let arrayData = [];
          // const data = response.data;
          // if (data !== null) {
          //   if (Array.isArray(data)) {
          //     arrayData = data;
          //   } else if (typeof data === "object" && data !== null) {
          //     arrayData = Object.entries(data).map(([key, value]) => ({
          //       id: key,
          //       ...value,
          //     }));
          //   } else {
          //     console.warn("Unexpected data format:", data);
          //   }
          // }
          setResult(response.data);
        }
      })
      .catch((err) => {
        console.warn("fetchData : App : " + err.code);
        setLoadingPage(false);
        setEmptys(true);
      });
  };
  const CreateArray = async () => {
    await AXIOS.put(`/LBG/ARRAY.json`, { COUNTER: 0 })
      .then((response) => {
        if (response.status === 200) {
          fetchData();
        }
      })
      .catch((err) => {
        console.warn(err);
        setEmptys(true);
      });
  };
  const HSLBGC = async () => {
    try {
      const response = await AXIOS.get(`/LBG.json`);
      if (response.data) {
        await fetchData();
        return;
      } else {
        await AXIOS.put(`/LBG/CONST.json`, {
          DATE: "",
          DAY: 1,
          ZEIT: true,
        });
        await CreateArray();
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.warn("Hinzufügen : HSLBGC : " + err.code);
        setEmptys(true);
      } else {
        console.warn("Hinzufügen : HSLBGC : " + err.code);
        setEmptys(true);
      }
    }
  };
  useEffect(() => {
    HSLBGC();
  }, []);
  return (
    <Box className="App">
      {LoadingPage ? (
        <Loadings />
      ) : Emptys ? (
        <Empty onClick={() => window.location.reload()} />
      ) : (
        <Fragment>
          <Bodi
            Result={Result}
            Menu={Menu}
            setMenu={setMenu}
            UbenArray={UbenArray}
            RegelnArray={RegelnArray}
            VerbArray={VerbArray}
            SubMenu={SubMenu}
            setSubMenu={setSubMenu}
            ShowItemsVerb={ShowItemsVerb}
            setShowItemsVerb={setShowItemsVerb}
            ShowItemsRegeln={ShowItemsRegeln}
            setShowItemsRegeln={setShowItemsRegeln}
          />
        </Fragment>
      )}
    </Box>
  );
}

export default App;
