import React from "react";
import classess from "./AlleVerb.module.css";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const Items = ({ OnClick, opt }) => {
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "rgb(30,30,30)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgb(30,30,30)",
      color: theme.palette.common.white,
      fontSize: "14px",
      fontFamily: "Tahoma",
      padding: "10px",
      letterSpacing: "1px",
    },
  }));
  const formatString = (str) => {
    if (!str) return "";
    const updatedStr = str.replace("(", "[").replace(")", "]");
    return updatedStr;
  };
  return (
    <Box className={classess.item} onClick={OnClick}>
      <BootstrapTooltip title={formatString(opt[0].split("-")[1])}>
        <span>{opt[0].split("-")[0]}</span>
      </BootstrapTooltip>
    </Box>
  );
};

export default Items;
