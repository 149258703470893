import { Box } from "@mui/material";
import React from "react";
import classess from "./Style.module.css";
import bird from "../../assets/bird2.jpg";

const Noch = ({ Result }) => {
  return (
    <Box className={classess.Bodi}>
      <img src={bird} alt="" />
      {Result[0][1].EN}
    </Box>
  );
};

export default Noch;
