import { Box } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import classess from "./Spiel.module.css";
import BTN from "../../components/btn/BTN";
import Unicode from "../../components/unicode/Unicode";
import AXIOS from "../../AXIOS";
import Empty from "../../components/empty/Empty";
import Loadings from "../../components/loading/Loadings";
import CurrentDate from "../../components/date/CurrentDate";
import EN from "./EN";
import GR from "./GR";
import Gif from "../../assets/giphy.gif";
import T3 from "../../assets/T3.jpg";

const Spiel = ({ setSubMenu }) => {
  const [Response, setResponse] = useState("");
  const [typingTimer, setTypingTimer] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [Disabeing, setDisabeing] = useState(false);
  const [Switching, setSwitching] = useState(false);
  const [TypeTyping, setTypeTyping] = useState("");
  const [Emptys, setEmptys] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(true);
  const [ResultConst, setResultConst] = useState([]);
  const [ResultData, setResultData] = useState([]);
  const [ResultArray, setResultArray] = useState([]);
  const [DisplayedData, setDisplayedData] = useState([]);
  const [Errors, setErrors] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [Continue, setContinue] = useState(true);
  const REF = useRef(null);
  const textareaRef = useRef(null);

  const SaveToFire = async (F) => {
    try {
      await Promise.all(
        F.map(async (item, index) => {
          await AXIOS.put(`/LBG/ARRAY/${index}.json`, {
            ID: item["ID"],
            GR: item["GR"],
            EN: item["EN"],
            FR: item["FR"],
            DATE: item["DATE"],
            DAY: item["DAY"],
            ERROR: item["ERROR"],
          });
        }),
        await AXIOS.patch("LBG/ARRAY.json", { COUNTER: F.length })
      );
      await fetchData();
    } catch (err) {
      console.warn(err);
      setEmptys(true);
    }
  };
  const FinallFetch = (setRD) => {
    setResultData(setRD);
    setDisplayedData([setRD[0]]);
    setLoadingPage(false);
  };
  const CONSTARRAY = (CONST_ARRAY) => {
    FinallFetch(
      Object.entries(CONST_ARRAY)
        .slice(0, -1)
        .map((item) => item[1])
    );
  };
  const UpdatePromises = (F, C) => {
    const updatePromises = F.map((item) => {
      return AXIOS.patch(`/LBG/DATA/${item.ID}.json`, {
        DAY: C,
      });
    });
    return updatePromises;
  };
  const fetchData = async (e) => {
    await AXIOS.get("/LBG.json")
      .then((response) => {
        if (response.status === 200) {
          setResultConst(response.data["CONST"]);
          const arrayKeys = Object.keys(response.data["ARRAY"]);
          setResultArray(arrayKeys.slice(0, -1));
          if (
            response.data["DATA"] === undefined ||
            response.data["DATA"] === null
          ) {
            setLoadingPage(false);
            console.warn("undefined ODER null");
            setEmptys(true);
          } else {
            // HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH
            // HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH
            const CONST_ARRAY = response.data["ARRAY"];
            const CONST_DATE = response.data["CONST"]["DATE"];
            const CONST_DAY = response.data["CONST"]["DAY"];
            const CONST_ZEIT = response.data["CONST"]["ZEIT"];
            const F0 = response.data["DATA"].filter((item) => item.DAY === 0);
            const F1 = response.data["DATA"].filter((item) => item.DAY === 1);
            const F2 = response.data["DATA"].filter((item) => item.DAY === 2);
            const F3 = response.data["DATA"].filter((item) => item.DAY === 3);
            const F4 = response.data["DATA"].filter((item) => item.DAY === 4);
            const F5 = response.data["DATA"].filter((item) => item.DAY === 5);
            const F6 = response.data["DATA"].filter((item) => item.DAY === 6);
            const F7 = response.data["DATA"].filter((item) => item.DAY === 7);
            const F8 = response.data["DATA"].filter((item) => item.DAY === 8);
            const F9 = response.data["DATA"].filter((item) => item.DAY === 9);
            const F10 = response.data["DATA"].filter((item) => item.DAY === 10);
            const F11 = response.data["DATA"].filter((item) => item.DAY === 11);
            const F12 = response.data["DATA"].filter((item) => item.DAY === 12);
            const F13 = response.data["DATA"].filter((item) => item.DAY === 13);
            const F14 = response.data["DATA"].filter((item) => item.DAY === 14);
            const F15 = response.data["DATA"].filter((item) => item.DAY === 15);
            const F16 = response.data["DATA"].filter((item) => item.DAY === 16);
            const F17 = response.data["DATA"].filter((item) => item.DAY === 17);
            const F18 = response.data["DATA"].filter((item) => item.DAY === 18);
            const F19 = response.data["DATA"].filter((item) => item.DAY === 19);
            const F20 = response.data["DATA"].filter((item) => item.DAY === 20);
            const F21 = response.data["DATA"].filter((item) => item.DAY === 21);
            const F22 = response.data["DATA"].filter((item) => item.DAY === 22);
            const F23 = response.data["DATA"].filter((item) => item.DAY === 23);
            const F24 = response.data["DATA"].filter((item) => item.DAY === 24);
            const F25 = response.data["DATA"].filter((item) => item.DAY === 25);
            const F26 = response.data["DATA"].filter((item) => item.DAY === 26);
            const F27 = response.data["DATA"].filter((item) => item.DAY === 27);
            const F28 = response.data["DATA"].filter((item) => item.DAY === 28);
            const F29 = response.data["DATA"].filter((item) => item.DAY === 29);
            const F30 = response.data["DATA"].filter((item) => item.DAY === 30);
            const F31 = response.data["DATA"].filter((item) => item.DAY === 31);
            // HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH
            // HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH
            if (CONST_DATE.length === 0 || CONST_DATE === CurrentDate) {
              if (!CONST_ZEIT) {
                setLoadingPage(false);
                setResultData([]);
              } else {
                let newData = [];
                if (CONST_DAY === 1) newData = [...F0];
                if (CONST_DAY === 2 || CONST_DAY === 3)
                  newData = [...F0, ...F1];
                if (CONST_DAY >= 4 || CONST_DAY <= 7) {
                  newData = [...F0, ...F1, ...F2];
                  if (CONST_ARRAY["COUNTER"] === 0) {
                    const U1 = UpdatePromises(F3, 2);
                    Promise.all(U1);
                  }
                }
                if (CONST_DAY >= 8 || CONST_DAY <= 15) {
                  newData = [...F0, ...F1, ...F2, ...F4];
                  if (CONST_ARRAY["COUNTER"] === 0) {
                    const U3 = UpdatePromises(F3, 2);
                    const U5 = UpdatePromises(F5, 4);
                    const U6 = UpdatePromises(F6, 5);
                    const U7 = UpdatePromises(F7, 6);
                    Promise.all(U3, U5, U6, U7);
                  }
                }
                if (CONST_DAY >= 16) {
                  newData = [
                    ...F0,
                    ...F1,
                    ...F2,
                    ...F4,
                    ...F8,
                    ...(CONST_DAY > 30 ? [...F16] : []),
                  ];
                  if (CONST_ARRAY["COUNTER"] === 0) {
                    const updates = [
                      UpdatePromises(F3, 2),
                      UpdatePromises(F5, 4),
                      UpdatePromises(F6, 5),
                      UpdatePromises(F7, 6),
                      UpdatePromises(F9, 8),
                      UpdatePromises(F10, 9),
                      UpdatePromises(F11, 10),
                      UpdatePromises(F12, 11),
                      UpdatePromises(F13, 12),
                      UpdatePromises(F14, 13),
                      UpdatePromises(F15, 14),
                      ...(CONST_DAY > 30
                        ? [
                            UpdatePromises(F17, 16),
                            UpdatePromises(F18, 17),
                            UpdatePromises(F19, 18),
                            UpdatePromises(F20, 19),
                            UpdatePromises(F21, 20),
                            UpdatePromises(F22, 21),
                            UpdatePromises(F23, 22),
                            UpdatePromises(F24, 23),
                            UpdatePromises(F25, 24),
                            UpdatePromises(F26, 25),
                            UpdatePromises(F27, 26),
                            UpdatePromises(F28, 27),
                            UpdatePromises(F29, 28),
                            UpdatePromises(F30, 29),
                          ]
                        : []),
                    ];

                    Promise.all(updates);
                  }
                }
                if (CONST_ARRAY["COUNTER"] === 0) SaveToFire(newData);
                else CONSTARRAY(CONST_ARRAY);
              }
            } else {
              AXIOS.patch(`/LBG.json`, {
                "CONST/DATE": CurrentDate,
                "CONST/DAY": CONST_DAY + 1,
                "CONST/ZEIT": true,
                "ARRAY/COUNTER": 0,
              })
                .then((response) => {
                  if (response.status === 200) fetchData();
                })
                .catch((err) => {
                  setLoadingPage(false);
                  console.warn("handleSubmit PATCH : Spiel : " + err);
                  setEmptys(true);
                });
            }
          }
        }
      })
      .catch((err) => {
        setLoadingPage(false);
        console.warn(err);
        setEmptys(true);
      });
  };
  const onChangehandle = (e) => {
    setResponse(e.target.value);
    setIsTyping(true);
    setTypeTyping("GR");
    if (typingTimer) {
      clearTimeout(typingTimer);
    }
    const timer = setTimeout(() => {
      setIsTyping(false);
    }, 500);
    setTypingTimer(timer);
  };
  const scrollToBottom = () => {
    if (REF.current) {
      REF.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const hasExcessiveSpaces = (input, maxSpaces = 2) => {
    const spacePattern = new RegExp(`\\s{${maxSpaces + 1},}`);
    return spacePattern.test(input);
  };
  const isResponseCorrect = () => {
    let ERR = false;
    if (hasExcessiveSpaces(Response)) {
      console.warn("Input contains excessive spaces!");
      setResponse("");
      return;
    }
    setDisabeing(true);
    if (
      Response.trim()
        .replace(/[^a-zA-Z0-9]/g, "")
        .toLowerCase() !==
      ResultData[currentIndex].GR.replace(/[^a-zA-Z0-9]/g, "").toLowerCase()
    ) {
      setErrors(true);
      ERR = true;
    }
    setDisplayedData((prev) => [...prev, ResultData[currentIndex]]);
    setTypeTyping("EN");
    setIsTyping(true);
    return ERR;
  };
  const handleSubmit = async () => {
    scrollToBottom();
    if (Response.trim() !== "") {
      let ERR = isResponseCorrect();
      await AXIOS.patch(`/LBG.json`, {
        [`DATA/${ResultData[currentIndex]["ID"]}/DAY`]: ERR
          ? 0
          : ResultConst["DAY"] === 1
          ? 1
          : ResultConst["DAY"] === 2 || ResultConst["DAY"] === 3
          ? ResultData[currentIndex]["DAY"] === 0
            ? 1
            : ResultConst["DAY"]
          : ResultConst["DAY"] >= 4 && ResultConst["DAY"] <= 7
          ? ResultData[currentIndex]["DAY"] === 0
            ? 1
            : ResultData[currentIndex]["DAY"] === 1
            ? 3
            : ResultConst["DAY"]
          : ResultConst["DAY"] >= 8 && ResultConst["DAY"] <= 15
          ? ResultData[currentIndex]["DAY"] === 0
            ? 1
            : ResultData[currentIndex]["DAY"] === 1
            ? 3
            : ResultData[currentIndex]["DAY"] === 2
            ? 7
            : ResultConst["DAY"]
          : ResultConst["DAY"] >= 16 && ResultConst["DAY"] <= 30
          ? ResultData[currentIndex]["DAY"] === 0
            ? 1
            : ResultData[currentIndex]["DAY"] === 1
            ? 3
            : ResultData[currentIndex]["DAY"] === 2
            ? 7
            : ResultData[currentIndex]["DAY"] === 4
            ? 15
            : ResultConst["DAY"]
          : ResultData[currentIndex]["DAY"] === 0
          ? 1
          : ResultData[currentIndex]["DAY"] === 1
          ? 3
          : ResultData[currentIndex]["DAY"] === 2
          ? 7
          : ResultData[currentIndex]["DAY"] === 4
          ? 15
          : ResultData[currentIndex]["DAY"] === 8
          ? 30
          : 31,
        [`DATA/${ResultData[currentIndex]["ID"]}/ERROR`]: ERR
          ? ResultData[currentIndex]["ERROR"] + 1
          : ResultData[currentIndex]["ERROR"],
        "CONST/DATE": CurrentDate,
        [`ARRAY/${ResultArray[currentIndex]}`]: null,
      })
        .then((response) => {
          if (response.status === 200) Finally();
        })
        .catch((err) => {
          setLoadingPage(false);
          console.warn("handleSubmit PATCH : Spiel : " + err);
          setEmptys(true);
        });
    }
  };
  const Finally = () => {
    textareaRef.current.focus();
    setTypeTyping("GR");
    setIsTyping(false);
    setErrors(false);
    setResponse("");
    setDisabeing(false);
    if (currentIndex >= ResultData.length - 1) {
      if (DisplayedData.length % 2 !== 0) {
        setResponse("");
        setSwitching(true);
        setDisabeing(true);
      }
      return;
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setDisplayedData((prev) => [...prev, ResultData[currentIndex + 1]]);
    }
  };
  useEffect(() => {
    if (!Continue) fetchData();
  }, [Continue]);
  return (
    <Box className={classess.Spiel}>
      {Continue ? (
        <Box className={classess.ENS3}>
          <img src={T3} alt="" />
          <span>Möchtest du weiterspielen?</span>
          <Box className={classess.Möchtest}>
            <Box className={classess.BTND2} onClick={() => setContinue(false)}>
              Ja!
            </Box>
            <Box
              className={classess.BTND3}
              onClick={() => setSubMenu("Hinzufügen")}
            >
              Nein!
            </Box>
          </Box>
        </Box>
      ) : LoadingPage ? (
        <Loadings />
      ) : ResultData.length === 0 || Emptys ? (
        <Empty onClick={() => window.location.reload()} />
      ) : (
        <Fragment>
          <Box className={classess.Up}>
            {/* {console.log(DisplayedData)} */}
            {DisplayedData.map((item, index) => (
              <Fragment key={index}>
                {index === 0 ? (
                  <EN Item={item} Type="" />
                ) : index !== 0 && index % 2 === 0 ? (
                  <EN Item={item} Type="" />
                ) : (
                  <GR GR={item.GR} Errors={Errors} GRDay={item.DAY} />
                )}
              </Fragment>
            ))}
            {isTyping ? (
              <Box
                className={classess.Gif}
                sx={{
                  alignItems: TypeTyping === "GR" ? "flex-end" : "flex-start",
                }}
              >
                <img src={Gif} alt="" />
              </Box>
            ) : null}
            <Box className={classess.ENS2}>
              {Switching ? (
                <Fragment>
                  <span>Möchtest du weiterspielen?</span>
                  <Box className={classess.Möchtest}>
                    <Box
                      className={classess.BTND2}
                      onClick={() => setResultData([])}
                    >
                      Ja!
                    </Box>
                    <Box
                      className={classess.BTND3}
                      onClick={() => {
                        setSwitching(false);
                        setDisabeing(true);
                      }}
                    >
                      Nein!
                    </Box>
                  </Box>
                </Fragment>
              ) : null}
            </Box>
            <Box className={classess.ENS1} ref={REF}></Box>
          </Box>
          <Unicode
            Response={Response}
            setResponse={setResponse}
            textareaRef={textareaRef}
          />
          <textarea
            ref={textareaRef}
            className={classess.Textarea}
            placeholder="Bitte geben Sie den richtigen Satz ein"
            value={Response}
            onChange={(e) => onChangehandle(e)}
            disabled={Switching || Disabeing ? true : false}
          />
          <Box className={classess.Fußzeile}>
            <span>
              Am <label>#{ResultConst["DAY"]}</label> Spieltag werden Ihnen{" "}
              <label>
                {currentIndex + 1}-{ResultData.length} '
              </label>{" "}
              Karten zugeteilt
            </span>
            <span>Setzen Sie Ihren Weg fort</span>
          </Box>
          {Disabeing ? (
            <BTN
              Text="Sparen ..."
              //onClick={onClick}
              P="fixed"
              W="340px"
              MH="50px"
              BC="#d8f3ee"
              C="#028472"
              MT="calc(100vh - 170px)"
              ML="0"
              B="1px solid #028472"
            />
          ) : (
            <BTN
              Text="Scheck Karte"
              onClick={handleSubmit}
              P="fixed"
              W="340px"
              MH="50px"
              BC="#028472"
              C="white"
              MT="calc(100vh - 170px)"
              ML="0"
              B="null"
            />
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default Spiel;
