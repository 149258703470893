import { Box } from "@mui/material";
import React from "react";
import classess from "./Loading.module.css";
import loading from "../../assets/laden.gif";

const Loadings = () => {
  return (
    <Box className={classess.Loading}>
      <img src={loading} alt="" />
      Laden ...
    </Box>
  );
};

export default Loadings;
