import { Box } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import classess from "./HinzufügenVerb.module.css";
import Unicode from "../../components/unicode/Unicode";
import RoadMap from "../../components/roadmap/RoadMap";
import BTN from "../../components/btn/BTN";
import AXIOS from "../../AXIOS";
import Empty from "../../components/empty/Empty";
import Loadings from "../../components/loading/Loadings";
// import DATAS from "../../../src/0.json";

const HinzufügenVerb = () => {
  const [GR, setGR] = useState("");
  const [F, setF] = useState([
    " - ()",
    " - ()",
    " - ()",
    " - ()",
    " - ()",
    " - ()",
  ]);
  const RoadMaps = ["Präsens", "Präteritum", "Perfekt", "Futur"];
  const Title = ["ICH", "DU", "ER/SIE/ES", "WIR", "IHR", "sie/Sie"];
  const English = [
    "⦗I⦘",
    "⦗You⦘",
    "⦗He/She/It⦘",
    "⦗We⦘",
    "⦗You-informal⦘",
    "⦗They/You-formal⦘",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [Result, setResult] = useState([]);
  const [R, setR] = useState([]);
  const [LoadSave, setLoadSave] = useState(false);
  const [Emptys, setEmptys] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(true);
  const REF1 = useRef(null);
  const REF2 = useRef(null);
  const REF3 = useRef(null);
  const REF4 = useRef(null);
  const REF5 = useRef(null);
  const REF6 = useRef(null);
  const REF7 = useRef(null);
  const [Response, setResponse] = useState("");
  const [InfinitivInput, setInfinitivInput] = useState("");
  const [InfinitivPosition, setInfinitivPosition] = useState(false);

  const hasExcessiveSpaces = (input, maxSpaces = 2) => {
    const spacePattern = new RegExp(`\\s{${maxSpaces + 1},}`);
    return spacePattern.test(input);
  };
  const handleShake = async () => {
    if (GR.trim().length !== 0 && currentIndex <= 3) {
      let ERR = false;
      F.forEach((field, index) => {
        if (field.trim().length === 0 || hasExcessiveSpaces(field)) {
          ERR = true;
          const updatedF = [...F];
          updatedF[index] = "";
          setF(updatedF);
        }
      });
      if (ERR) {
        console.warn("Input contains excessive spaces!");
        return;
      } else {
        const A = {
          ICH: F[0],
          DU: F[1],
          ER: F[2],
          WIR: F[3],
          IHR: F[4],
          SIE: F[5],
        };
        const updatedF = [...R];
        updatedF[currentIndex] = A;
        setR(updatedF);
        if (currentIndex + 1 === 2) setInfinitivPosition(true);
        if (currentIndex === 2) {
          const updatedF = [...F];
          updatedF[0] =
            "werde " + GR.split("-")[0].toLowerCase() + " - (viyade )";
          updatedF[1] =
            "wirst " + GR.split("-")[0].toLowerCase() + " - (vest )";
          updatedF[2] = "wird " + GR.split("-")[0].toLowerCase() + " - (vet )";
          updatedF[3] =
            "werden " + GR.split("-")[0].toLowerCase() + " - (viyaden )";
          updatedF[4] =
            "werdet " + GR.split("-")[0].toLowerCase() + " - (viyadet )";
          updatedF[5] =
            "werden  " + GR.split("-")[0].toLowerCase() + " - (viyaden )";
          setF(updatedF);
        }
        if (currentIndex >= 3) {
          setLoadSave(true);
          try {
            await Promise.all(
              RoadMaps.map(async (item, index) => {
                await AXIOS.put(`/LBG/VERB/${GR.trim()}/${item}.json`, {
                  ICH: updatedF[index].ICH,
                  DU: updatedF[index].DU,
                  ER: updatedF[index].ER,
                  WIR: updatedF[index].WIR,
                  IHR: updatedF[index].IHR,
                  SIE: updatedF[index].SIE,
                });
              })
            );
            setLoadSave(false);
            setGR("");
            setCurrentIndex(0);
            await fetchData();
          } catch (err) {
            console.warn(err);
            setEmptys(true);
          }
        }
        if (currentIndex <= 2) {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % RoadMaps.length);
        }
      }
      if (currentIndex !== 2)
        setF([" - ()", " - ()", " - ()", " - ()", " - ()", " - ()"]);
    }
  };
  const fetchData = async () => {
    await AXIOS.get("/LBG/VERB.json")
      .then((response) => {
        if (response.status === 200) {
          setLoadingPage(false);
          setResult(
            response.data === null ? "" : Object.entries(response.data)
          );
          //console.log(Object.entries(response.data)[0][1].Futur);
          //FD();
        }
      })
      .catch((err) => {
        console.warn(err);
        setEmptys(true);
      });
  };
  const HandlePerfekt = (e) => {
    if (InfinitivInput.length !== 0) {
      setInfinitivPosition(false);
      const updatedF = [...F];
      updatedF[0] =
        e === "Haben" ? "habe " + InfinitivInput + " - (habe )" : null;
      updatedF[1] =
        e === "Haben" ? "hast " + InfinitivInput + " - (hast )" : null;
      updatedF[2] =
        e === "Haben" ? "hat " + InfinitivInput + " - (hat )" : null;
      updatedF[3] =
        e === "Haben" ? "haben " + InfinitivInput + " - (haben )" : null;
      updatedF[4] =
        e === "Haben" ? "habt " + InfinitivInput + " - (habt )" : null;
      updatedF[5] =
        e === "Haben" ? "haben  " + InfinitivInput + " - ( )" : null;
      setF(updatedF);
    }
  };
  // const VV = (A, n, T) => {
  //   let C = null;
  //   if (T === "ICH")
  //     C =
  //       A[n].ICH.split(".")[0].trim() +
  //       " - (" +
  //       A[n].ICH.split(".")[1].trim() +
  //       ")";
  //   if (T === "DU")
  //     C =
  //       A[n].DU.split(".")[0].trim() +
  //       " - (" +
  //       A[n].DU.split(".")[1].trim() +
  //       ")";
  //   if (T === "ER")
  //     C =
  //       A[n].ER.split(".")[0].trim() +
  //       " - (" +
  //       A[n].ER.split(".")[1].trim() +
  //       ")";
  //   if (T === "IHR")
  //     C =
  //       A[n].IHR.split(".")[0].trim() +
  //       " - (" +
  //       A[n].IHR.split(".")[1].trim() +
  //       ")";
  //   if (T === "SIE")
  //     C =
  //       A[n].SIE.split(".")[0].trim() +
  //       " - (" +
  //       A[n].SIE.split(".")[1].trim() +
  //       ")";
  //   if (T === "WIR")
  //     C =
  //       A[n].WIR.split(".")[0].trim() +
  //       " - (" +
  //       A[n].WIR.split(".")[1].trim() +
  //       ")";
  //   return C;
  // };
  // const FD = async () => {
  //   const A = Object.entries(DATAS)[0][1][42];
  //   const Norm =
  //     A[0].GR.split(".")[0].trim() +
  //     " - (" +
  //     A[0].GR.split(".")[1].trim() +
  //     ")";
  //   const OBJ = {
  //     Futur: {
  //       ICH: VV(A, 2, "ICH"),
  //       DU: VV(A, 2, "DU"),
  //       ER: VV(A, 2, "ER"),
  //       IHR: VV(A, 2, "IHR"),
  //       SIE: VV(A, 2, "SIE"),
  //       WIR: VV(A, 2, "WIR"),
  //     },
  //     Perfekt: {
  //       ICH: VV(A, 3, "ICH"),
  //       DU: VV(A, 3, "DU"),
  //       ER: VV(A, 3, "ER"),
  //       IHR: VV(A, 3, "IHR"),
  //       SIE: VV(A, 3, "SIE"),
  //       WIR: VV(A, 3, "WIR"),
  //     },
  //     Präsens: {
  //       ICH: VV(A, 0, "ICH"),
  //       DU: VV(A, 0, "DU"),
  //       ER: VV(A, 0, "ER"),
  //       IHR: VV(A, 0, "IHR"),
  //       SIE: VV(A, 0, "SIE"),
  //       WIR: VV(A, 0, "WIR"),
  //     },
  //     Präteritum: {
  //       ICH: VV(A, 1, "ICH"),
  //       DU: VV(A, 1, "DU"),
  //       ER: VV(A, 1, "ER"),
  //       IHR: VV(A, 1, "IHR"),
  //       SIE: VV(A, 1, "SIE"),
  //       WIR: VV(A, 1, "WIR"),
  //     },
  //   };
  //   console.log(Norm);
  //   await AXIOS.put(`/LBG/VERB/${Norm}.json`, OBJ)
  //     .then((response) => {
  //       if (response.status === 200) {
  //       }
  //     })
  //     .catch((err) => {
  //       console.warn(err);
  //       setEmptys(true);
  //     });
  // };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box className={classess.Up}>
      {LoadingPage ? (
        <Loadings />
      ) : Emptys ? (
        <Empty onClick={() => window.location.reload()} />
      ) : (
        <Fragment>
          <RoadMap RoadMaps={RoadMaps} currentIndex={currentIndex} />
          <Unicode
            Response={
              Response === "GR"
                ? GR
                : Response === "ICH"
                ? F[0]
                : Response === "DU"
                ? F[1]
                : Response === "ER/SIE/ES"
                ? F[2]
                : Response === "WIR"
                ? F[3]
                : Response === "IHR"
                ? F[4]
                : F[5]
            }
            setResponse={
              Response === "GR"
                ? setGR
                : Response === "ICH"
                ? (newValue) => {
                    const updatedF = [...F];
                    updatedF[0] = newValue;
                    setF(updatedF);
                  }
                : Response === "DU"
                ? (newValue) => {
                    const updatedF = [...F];
                    updatedF[1] = newValue;
                    setF(updatedF);
                  }
                : Response === "ER/SIE/ES"
                ? (newValue) => {
                    const updatedF = [...F];
                    updatedF[2] = newValue;
                    setF(updatedF);
                  }
                : Response === "WIR"
                ? (newValue) => {
                    const updatedF = [...F];
                    updatedF[3] = newValue;
                    setF(updatedF);
                  }
                : Response === "IHR"
                ? (newValue) => {
                    const updatedF = [...F];
                    updatedF[4] = newValue;
                    setF(updatedF);
                  }
                : (newValue) => {
                    const updatedF = [...F];
                    updatedF[5] = newValue;
                    setF(updatedF);
                  }
            }
            textareaRef={
              Response === "GR"
                ? REF1
                : Response === "ICH"
                ? REF2
                : Response === "DU"
                ? REF3
                : Response === "ER/SIE/ES"
                ? REF4
                : Response === "WIR"
                ? REF5
                : Response === "IHR"
                ? REF6
                : REF7
            }
          />
          <input
            className={classess.GR}
            placeholder="Betreten Sie Duetch"
            value={GR}
            onChange={(e) => setGR(e.target.value)}
            onKeyDown={() => setResponse("GR")}
          />
          {(currentIndex === 2 || currentIndex === 3) && InfinitivPosition ? (
            <Box className={classess.Choosen}>
              <span className={classess.ChoosenTitile}>
                "Bitte wählen Sie eine Option, um fortzufahren."
              </span>
              <input
                className={classess.ChoosenInput}
                placeholder="Infinitiv Perfekt"
                value={InfinitivInput}
                onChange={(e) => setInfinitivInput(e.target.value)}
              />
              <Box className={classess.Möchtest}>
                <Box
                  className={classess.BTND3}
                  onClick={() => HandlePerfekt("Haben")}
                >
                  Haben
                </Box>
                <Box
                  className={classess.BTND3}
                  onClick={() => HandlePerfekt("Sinen")}
                >
                  Sinen
                </Box>
              </Box>
            </Box>
          ) : null}
          {Title.map((Option, index) =>
            !InfinitivPosition ? (
              <Box key={index} className={classess.InputBox}>
                <span>{Option}</span>
                <span className={classess.English}>{English[index]}</span>
                <input
                  className={
                    index === 2
                      ? classess.InputVerb2
                      : index === 5
                      ? classess.InputVerb3
                      : classess.InputVerb1
                  }
                  placeholder={`füll ⦗${Option}⦘ aus`}
                  value={F[index]}
                  onChange={(e) => {
                    const updatedF = [...F];
                    updatedF[index] = e.target.value;
                    setF(updatedF);
                  }}
                  onKeyDown={() => setResponse(Option)}
                  ref={
                    Option === "GR"
                      ? REF1
                      : Option === "ICH"
                      ? REF2
                      : Option === "DU"
                      ? REF3
                      : Option === "ER/SIE/ES"
                      ? REF4
                      : Option === "WIR"
                      ? REF5
                      : Option === "IHR"
                      ? REF6
                      : REF7
                  }
                />
              </Box>
            ) : null
          )}
          {!InfinitivPosition ? (
            <Box className={classess.Verben}>
              Anzahl der gespeicherten Verben: {Result.length}'
            </Box>
          ) : null}
          {currentIndex === 3 ? (
            LoadSave ? (
              <BTN
                Text="Laden ..."
                // onClick={handleShake}
                P="fixed"
                W="340px"
                MH="50px"
                BC="#d8f3ee"
                C="#028472"
                MT="calc(100vh - 170px)"
                ML="0"
                B="1px solid #028472"
              />
            ) : (
              <BTN
                Text="Karte Hinzufügen"
                onClick={handleShake}
                P="fixed"
                W="340px"
                MH="50px"
                BC="#028472"
                C="white"
                MT="calc(100vh - 170px)"
                ML="0"
                B="null"
              />
            )
          ) : (
            <BTN
              Text={`Nächste → ${RoadMaps[currentIndex + 1]}`}
              onClick={handleShake}
              P="fixed"
              W="340px"
              MH="50px"
              BC="#d8f3ee"
              C="#028472"
              MT="calc(100vh - 170px)"
              ML="0"
              B="1px solid #028472"
            />
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default HinzufügenVerb;
