import { Box } from "@mui/material";
import React from "react";
import empty from "../../assets/empty.webp";
import classess from "./Empty.module.css";
import BTN from "../btn/BTN";

const Empty = ({ onClick }) => {
  return (
    <Box className={classess.Empty}>
      <img src={empty} alt="" />
      <Box className={classess.EmptyText1}>
        Aaaah! Etwas ist schief gelaufen
      </Box>
      <Box className={classess.EmptyText2}>
        Möglicherweise haben Sie noch keine Karte eingesetzt oder es liegt ein
        Systemproblem vor. Versuchen Sie es erneut
      </Box>
      <BTN
        Text="Versuchen Sie es erneut"
        onClick={onClick}
        P="relative"
        W="60%"
        MH="50px"
        BC="#d70022"
        C="white"
        MT="30px"
        ML="0"
        B="null"
      />
    </Box>
  );
};

export default Empty;
