import { Box } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import classess from "./BesondersRegeln.module.css";
import coffee from "../../assets/coffee.jpg";
import sun from "../../assets/lamp.jpg";
import cloude2 from "../../assets/cloude2.jpg";
import lampe2 from "../../assets/lampe2.jpg";
import Loadings from "../../components/loading/Loadings";
import Empty from "../../components/empty/Empty";
import AXIOS from "../../AXIOS";
import Unicode from "../../components/unicode/Unicode";
import search from "../../assets/search.jpg";
import C1 from "../../assets/C1.png";
import C2 from "../../assets/C2.png";
import C3 from "../../assets/C3.png";
import Car from "../../assets/Car.jpg";
import cicle from "../../assets/cicle.jpg";
import Ballon from "../../assets/Ballon.jpg";
import Sheep from "../../assets/Sheep.jpg";

const BesondersRegeln = () => {
  const [LoadingPage, setLoadingPage] = useState(true);
  const [Result, setResult] = useState([]);
  const [Emptys, setEmptys] = useState(false);
  const REF1 = useRef(null);
  const REF2 = useRef(null);
  const [Typ, setTyp] = useState("");
  const [Wort, setWort] = useState("");
  const [LoadSave, setLoadSave] = useState(false);
  const [Response, setResponse] = useState("");
  const [Edit, setEdit] = useState(null);

  const Handle = async (event) => {
    setResponse("Wort");
    if (event.key === "Enter" && Typ.length !== 0 && Wort.length !== 0) {
      setLoadSave(true);
      await AXIOS.put(
        `/LBG/BESONDER/${
          Edit === null ? (Result === null ? 0 : Result.length) : Edit
        }.json`,
        {
          ID: Edit === null ? (Result === null ? 0 : Result.length) : Edit,
          TYP: Typ.trim().toUpperCase(),
          WORT: Wort.trim(),
        }
      )
        .then((response) => {
          if (response.status === 200) {
            fetchData();
            setLoadSave(false);
            setWort("");
            setTyp("");
            setLoadSave("");
          }
        })
        .catch((err) => {
          console.warn(err);
          setEmptys(true);
        });
    }
  };
  const fetchData = async () => {
    await AXIOS.get("/LBG/BESONDER.json")
      .then((response) => {
        if (response.status === 200) {
          setLoadingPage(false);
          setResult(response.data);
          // console.log(response.data);
          // console.log(response.data.filter((item) => item.ID === 83));
        }
      })
      .catch((err) => {
        console.warn(err);
        setEmptys(true);
      });
  };
  const groupedData = (Result || []).reduce((acc, item) => {
    if (!acc[item.TYP]) {
      acc[item.TYP] = [];
    }
    acc[item.TYP].push(item);
    return acc;
  }, {});
  const Change = (opt, item) => {
    setTyp(opt);
    setWort(item.WORT);
    setEdit(item.ID);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box className={classess.Up}>
      {LoadingPage ? (
        <Loadings />
      ) : Emptys ? (
        <Empty onClick={() => window.location.reload()} />
      ) : (
        <Fragment>
          <Box className={classess.Tops}>
            <Unicode
              Response={Response === "Typ" ? Typ : Wort}
              setResponse={Response === "Typ" ? setTyp : setWort}
              textareaRef={Response === "Typ" ? REF1 : REF2}
            />
            <Box className={classess.Forms}>
              <input
                className={classess.Typ}
                placeholder="Typ"
                value={Typ}
                onChange={(e) => setTyp(e.target.value)}
                ref={REF1}
                disabled={LoadSave ? true : false}
                onKeyDown={() => setResponse("Typ")}
              />
              <input
                className={classess.Wort}
                placeholder="Wort"
                value={Wort}
                onChange={(e) => setWort(e.target.value)}
                ref={REF2}
                disabled={LoadSave ? true : false}
                onKeyDown={Handle}
              />
            </Box>
          </Box>
          <Box className={classess.Buttons}>
            {Result === null || Result.length === 0 ? (
              <Box className={classess.EmptyText}>
                <img src={search} alt="" />
                Es gibt kein besonderes Wort
              </Box>
            ) : (
              Object.keys(groupedData).map((opt, index) => (
                <Fragment key={index}>
                  <Box key={index} className={classess.th0}>
                    {index === 0 ? <img src={coffee} alt="" /> : null}
                    {index === 1 ? <img src={sun} alt="" /> : null}
                    {index === 2 ? <img src={cloude2} alt="" /> : null}
                    {index === 3 ? <img src={lampe2} alt="" /> : null}
                    {index === 4 ? <img src={C1} alt="" /> : null}
                    {index === 5 ? <img src={C2} alt="" /> : null}
                    {index === 6 ? <img src={C3} alt="" /> : null}
                    {index === 7 ? <img src={Car} alt="" /> : null}
                    {index === 8 ? <img src={Ballon} alt="" /> : null}
                    {index === 9 ? <img src={Sheep} alt="" /> : null}
                    {index === 10 ? <img src={cicle} alt="" /> : null}
                    <span>{opt}</span>
                  </Box>
                  <Box className={classess.th1}>
                    {groupedData[opt].map((item, ID) => (
                      <Box
                        key={ID}
                        className={classess.th2}
                        onClick={() => Change(opt, item)}
                      >
                        {item.WORT}
                      </Box>
                    ))}
                  </Box>
                </Fragment>
              ))
            )}
            <Box sx={{ width: "100%", minHeight: "150px" }}></Box>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default BesondersRegeln;
