import { Box } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import classess from "./AlleRegeln.module.css";
import AXIOS from "../../AXIOS";
import Empty from "../../components/empty/Empty";
import Loadings from "../../components/loading/Loadings";
import SearchIcon from "@mui/icons-material/Search";
import Items from "../alle_verb/Items";
import Akkusativ from "../../components/const/Akkusativ";
import bird from "../../assets/bird.jpg";
import headers from "../../assets/header.jpg";
import Dative from "../../components/const/Dative";
import Tree from "../../components/const/Tree";
import Articles from "../../components/const/Articles";
import Einen from "../../components/const/Einen";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import Nicht from "../../components/const/Nicht";

const AlleRegeln = ({ ShowItemsRegeln, setShowItemsRegeln, setSubMenu }) => {
  const [LoadingPage, setLoadingPage] = useState(true);
  const [Result, setResult] = useState([]);
  const [Emptys, setEmptys] = useState(false);

  const fetchData = async () => {
    await AXIOS.get("/LBG/REGELN.json")
      .then((response) => {
        if (response.status === 200) {
          setLoadingPage(false);
          setResult(
            response.data === null ? "" : Object.entries(response.data)
          );
        }
      })
      .catch((err) => {
        console.warn(err);
        setEmptys(true);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box className={classess.Up}>
      {LoadingPage ? (
        <Loadings />
      ) : Result.length === 0 || Emptys ? (
        <Empty onClick={() => window.location.reload()} />
      ) : ShowItemsRegeln === null ? (
        <Fragment>
          <Box className={classess.Search}>
            <Box className={classess.InputContainer}>
              <input className={classess.Input} placeholder="Search..." />
              <span className={classess.SearchIcon}>
                <SearchIcon className={classess.SearchIcons} />
              </span>
            </Box>
          </Box>
          <Box className={classess.Items}>
            {Result.map((opt, index) =>
              opt[0] !== "NOCH" ? (
                <Items
                  key={index}
                  OnClick={() => setShowItemsRegeln(opt)}
                  opt={opt}
                />
              ) : null
            )}
          </Box>
        </Fragment>
      ) : (
        <Box className={classess.Itemsdetailes}>
          <Box className={classess.Header}>
            <Box className={classess.H1}>
              <img src={headers} alt="" className={classess.angoor} />
              <span>Lesen Sie den folgenden Text sorgfältig</span>
            </Box>
            <Box className={classess.H2}>
              <AppRegistrationRoundedIcon
                sx={{
                  fontSize: "1.3rem",
                  color: "rgb(0, 0, 0, 0.7)",
                  cursor: "pointer",
                  marginTop: "5px",
                }}
                onClick={() => {
                  setSubMenu("Hinzufügen");
                  setShowItemsRegeln(null);
                  localStorage.setItem(
                    "Regeln",
                    JSON.stringify(ShowItemsRegeln)
                  );
                }}
              />
            </Box>
          </Box>
          <Box className={classess.Bodi}>
            <img src={bird} alt="" className={classess.comma} />
            {ShowItemsRegeln[0] === "تفاوت‌داتیو و آکوزاتیو" ? (
              <Fragment>
                <span>Akkusativ</span>
                <Akkusativ />
                <span>Dativ</span>
                <Dative />
              </Fragment>
            ) : null}
            <span>{ShowItemsRegeln[1].EN}</span>
            {ShowItemsRegeln[0] === "Akkusativ" ? <Akkusativ /> : null}
            {ShowItemsRegeln[0] === "dativ" ? <Dative /> : null}
            {ShowItemsRegeln[0] === "Articles" ? <Articles /> : null}
            {ShowItemsRegeln[0] === "Ich habe einen Freund" ? <Einen /> : null}
            {ShowItemsRegeln[0] === "منفی کردن آلمانی" ? (
              <Nicht Result={Result.filter((item) => item[0] === "NOCH")} />
            ) : null}
            <Tree T={ShowItemsRegeln[0]} />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AlleRegeln;
