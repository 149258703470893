import { Box } from "@mui/material";
import React, { Fragment } from "react";
import classess from "./Gesamt.module.css";
import { useEffect, useState } from "react";
import AXIOS from "../../AXIOS";
import Empty from "../../components/empty/Empty";
import Loadings from "../../components/loading/Loadings";
import EN from "../spiel/EN";
import GR from "../spiel/GR";
import SearchIcon from "@mui/icons-material/Search";

const Gesamt = ({ setSubMenu }) => {
  const [ResultData, setResultData] = useState([]);
  const [LoadingPage, setLoadingPage] = useState(true);
  const [Emptys, setEmptys] = useState(false);

  const fetchData = async () => {
    await AXIOS.get("/LBG.json")
      .then((response) => {
        if (response.status === 200) {
          if (
            response.data["DATA"] === undefined ||
            response.data["DATA"] === null
          ) {
            setLoadingPage(false);
            console.warn("undefined ODER null");
            setEmptys(true);
          } else {
            setResultData(response.data["DATA"]);
            setLoadingPage(false);
          }
        }
      })
      .catch((err) => {
        setLoadingPage(false);
        console.warn(err);
        setEmptys(true);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box className={classess.Gesamt}>
      {LoadingPage ? (
        <Loadings />
      ) : ResultData.length === 0 || Emptys ? (
        <Empty onClick={() => window.location.reload()} />
      ) : (
        <Box className={classess.Up}>
          <Box className={classess.Search}>
            <Box className={classess.InputContainer}>
              <input className={classess.Input} placeholder="Search..." />
              <span className={classess.SearchIcon}>
                <SearchIcon className={classess.SearchIcons} />
              </span>
            </Box>
          </Box>
          <Box className={classess.UpBodi}>
            {ResultData.map((item, index) => (
              <Fragment key={index}>
                <EN Item={item} Type="Gesamt" setSubMenu={setSubMenu} />
                <GR GR={item.EN} Errors={false} GRDay={item.DAY} />
              </Fragment>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Gesamt;
