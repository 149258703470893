import React, { useState, useEffect, Fragment, useRef } from "react";
import classess from "./Hinzufügen.module.css";
import { Box } from "@mui/material";
import BTN from "../../components/btn/BTN";
import Unicode from "../../components/unicode/Unicode";
import AXIOS from "../../AXIOS";
import CurrentDate from "../../components/date/CurrentDate";
import Loadings from "../../components/loading/Loadings";
import Empty from "../../components/empty/Empty";

const Hinzufügen = ({ setSubMenu }) => {
  const [LoadingPage, setLoadingPage] = useState(true);
  const [LoadSave, setLoadSave] = useState(false);
  const [Result, setResult] = useState([]);
  const [GR, setGR] = useState("");
  const [EN, setEN] = useState("");
  const [FR, setFR] = useState("");
  const [Emptys, setEmptys] = useState(false);
  const REF1 = useRef(null);
  const REF2 = useRef(null);
  const REF3 = useRef(null);
  const [Response, setResponse] = useState("");
  const storedItem = localStorage.getItem("Item");
  const [Stored, setStored] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (GR.length !== 0 && EN.length !== 0) {
      setLoadSave(true);
      await AXIOS.put(
        `/LBG/DATA/${
          Stored === null ? (Result === null ? 0 : Result.length) : Stored.ID
        }.json`,
        {
          ID:
            Stored === null ? (Result === null ? 0 : Result.length) : Stored.ID,
          GR: GR.trim(),
          EN: EN.trim(),
          FR: FR.trim(),
          DATE: Stored === null ? CurrentDate : Stored.DATE,
          DAY: Stored === null ? 0 : Stored.DAY,
          ERROR: Stored === null ? 0 : Stored.ERROR,
        }
      )
        .then((response) => {
          if (response.status === 200) {
            fetchData();
            setLoadSave(false);
            setGR("");
            setEN("");
            setFR("");
            if (Stored !== null) setSubMenu("Gesamt");
          }
        })
        .catch((err) => {
          console.warn(err);
          setEmptys(true);
        });
    }
  };
  const fetchData = async () => {
    await AXIOS.get("/LBG/DATA.json")
      .then((response) => {
        if (response.status === 200) {
          setLoadingPage(false);
          // let arrayData = [];
          // const data = response.data;
          // if (data !== null) {
          //   if (Array.isArray(data)) {
          //     arrayData = data;
          //   } else if (typeof data === "object" && data !== null) {
          //     arrayData = Object.entries(data).map(([key, value]) => ({
          //       id: key,
          //       ...value,
          //     }));
          //   } else {
          //     console.warn("Unexpected data format:", data);
          //   }
          // }
          setResult(response.data);
        }
      })
      .catch((err) => {
        console.warn(err);
        setEmptys(true);
      });
  };
  useEffect(() => {
    fetchData();
    if (storedItem) {
      const parsedItem = JSON.parse(storedItem);
      setGR(parsedItem.GR);
      setEN(parsedItem.EN);
      setFR(parsedItem.FR);
      setStored(parsedItem);
    }
    localStorage.removeItem("Item");
  }, []);

  return (
    <Box className={classess.Hinzufügen}>
      {LoadingPage ? (
        <Loadings />
      ) : Emptys ? (
        <Empty onClick={() => window.location.reload()} />
      ) : (
        <Fragment>
          <Unicode
            Response={Response === "GR" ? GR : Response === "EN" ? EN : FR}
            setResponse={
              Response === "GR" ? setGR : Response === "EN" ? setEN : setFR
            }
            textareaRef={
              Response === "GR" ? REF1 : Response === "EN" ? REF2 : REF3
            }
          />
          <textarea
            className={classess.GR}
            placeholder="Betreten Sie Deutschland"
            value={GR}
            onChange={(e) => setGR(e.target.value)}
            onKeyDown={() => setResponse("GR")}
            ref={REF1}
          />
          <textarea
            className={classess.EN}
            placeholder="Betreten Sie English"
            value={EN}
            onChange={(e) => setEN(e.target.value)}
            onKeyDown={() => setResponse("EN")}
            ref={REF2}
          />
          <textarea
            className={classess.FR}
            placeholder="Betreten Sie Persisch"
            value={FR}
            onChange={(e) => setFR(e.target.value)}
            onKeyDown={() => setResponse("FR")}
            ref={REF3}
          />
          <span className={classess.Footer}>
            Die Gesamtzahl aller gespeicherten Karten beträgt
            <label> {Result === null ? "0" : Result.length} '</label>
          </span>
          {LoadSave ? (
            <BTN
              Text="Laden ..."
              //onClick={onClick}
              P="fixed"
              W="340px"
              MH="50px"
              BC="#d8f3ee"
              C="#028472"
              MT="calc(100vh - 170px)"
              ML="0"
              B="1px solid #028472"
            />
          ) : Stored !== null ? (
            <BTN
              Text="Karte Bearbeiten"
              onClick={handleSubmit}
              P="fixed"
              W="340px"
              MH="50px"
              BC="#028472"
              C="white"
              MT="calc(100vh - 170px)"
              ML="0"
              B="null"
            />
          ) : (
            <BTN
              Text="Karte Hinzufügen"
              onClick={handleSubmit}
              P="fixed"
              W="340px"
              MH="50px"
              BC="#028472"
              C="white"
              MT="calc(100vh - 170px)"
              ML="0"
              B="null"
            />
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default Hinzufügen;
