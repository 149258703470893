import React from "react";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const ArrowForwardIos = () => {
  return (
    <ArrowForwardIosRoundedIcon
      sx={{
        fontSize: "0.7rem",
        marginLeft: "3px",
        marginTop: "1px",
        marginRight: "3px",
      }}
    />
  );
};

export default ArrowForwardIos;
